.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

@keyframes spin {
    0% { 
        transform: rotate(0deg); 
    }
    100% { 
        transform: rotate(360deg); 
    }
}

.spinner-border {
    animation: spin 1s linear infinite;
}

@keyframes pop {
    0% { 
        transform: scale(0.8); 
        opacity: 0; 
    }
    100% { 
        transform: scale(1); 
        opacity: 1; 
    }
}

.text-success {
    animation: pop 0.3s ease-out;
}