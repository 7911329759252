.button {
    position: relative;
    max-height: 54px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: none;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.fullWidth {
    width: 100%;
    max-width: 350px;
    align-self: center;
}

.button:disabled {
    opacity: 0.6;
}

.dark {
    background-color: var(--primary-color);
    color: #ffffff;
}

.light {
    background-color: #e4e3e3;
    color: var(--primary-color);
}
