.button {
    position: relative;
    padding: 15px 8px 15px 8px;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.fullWidth {
    width: 100%;
}

.checked {
    background-color: rgba(0, 161, 36, 1);
}

.unchecked {
    background-color: rgba(124, 124, 124, 1);
}

.button:disabled {
    background-color: var(--primary-color);
    color: #ffffff;
    opacity: 0.6;
    cursor: not-allowed;
}
