.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.selectWrapper {
    position: relative;
    width: 100%;
    z-index: 1;
}

.select {
    position: relative;
    width: 100%;
    /* padding: 1vh 2vw; */
    padding: 8px 12px;
    background: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(250, 250, 250, 1);
    /* border-radius: 1vh; */
    border-radius: 8px;
    outline: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transform: translateZ(0);
}

.selectWrapper::after {
    content: '';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}

.select:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.1);
}

/* Style for disabled/placeholder option */
.select option[value=''][disabled] {
    color: #6b7280;
}

/* Style for actual options */
.select option:not([value=''][disabled]) {
    color: #000000;
    background: #ffffff;
    padding: 12px;
}

@media (max-width: 768px) {
    .select {
        font-size: 16px;
        padding: 8px 12px;
    }
}
