.container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.fullWidth {
    width: 100%;
}

.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.input {
    position: relative;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
    background: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(250, 250, 250, 1);
    border-radius: 1vh;
    outline: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
}

.input:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

/* Override react-datepicker styles */
:global(.react-datepicker-popper) {
    z-index: 9999 !important;
}

:global(.react-datepicker-wrapper),
:global(.react-datepicker__input-container) {
    display: block;
    width: 100%;
}
