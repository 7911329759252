.icon-container {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 0.9rem !important;
    padding: 0.3rem 0.75rem !important;
}

.icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-in-out;
}

.icon-container:hover {
    cursor: pointer;
}

.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

.copy-badge:hover {
    cursor: pointer;
}

.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
