.notifications-dropdown-show {
    /* opacity: 1; */
    display: block !important;
}

.notifications-dropdown-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9998; /* Ensure the backdrop is above other content */
}
