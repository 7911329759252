.button {
    position: relative;

    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffefe;
    border: 1px solid #fafafa;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px #2f2f2f1f;
}

.button:disabled {
    opacity: 0.6;
}
