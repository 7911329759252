.container {
    position: relative;
}

.fullWidth {
    width: 100%;
}

.iconWrapper {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.focused {
    opacity: 0;
    transform: translateY(0) scale(0.8);
}

.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.textinput {
    position: relative;
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
    background-color: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(250, 250, 250, 1);
    border-radius: 1vh;
    outline: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
}

.rounded {
    border-radius: 5vh;
}

.iconPadding {
    padding: 0.5rem 3rem 0.5rem 3rem;
}

.textinput::placeholder {
    color: #7c7c7c;
}

.textinput:focus {
    border: 1px solid #141723;
    padding: 0.5rem 2rem 0.5rem 1rem;
}

.textinput.disabled {
    background-color: rgba(0, 0, 0, 0.1) !important;
    cursor: not-allowed !important;
}
