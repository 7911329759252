.label {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.range {
    position: relative;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: rgba(255, 254, 254, 0.9);
    border: 1px solid rgba(250, 250, 250, 1);
    border-radius: 1vh;
    outline: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;
}

.range::placeholder {
    color: #7c7c7c;
}

.range:focus {
    border: 1px solid #141723;
}

.range.disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}
