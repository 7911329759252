@keyframes scaleIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.checkboxContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.checkboxRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}

.checkbox {
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid rgba(200, 200, 200, 1);
    border-radius: 2px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.checkbox:checked {
    background-color: white;
}

.checkbox::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background-color: #4b5580;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease-out;
}

.checkbox:checked::before {
    transform: scale(1);
    opacity: 1;
    animation: scaleIn 0.15s ease-in-out;
}

.checkbox::-ms-check {
    display: none;
}
