@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* Poppins font */
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #141723;
    --text-color-dark: #202226;
    --text-color-grey: #838383;
    --text-color-light: #ffffff;
    --text-color-green: #04cb00;
    /* --primary-color: #59baff;
    --secondary-color: #005eb5;
    --heading-color: #1e1e1e;
    --text-color: #4d5c64; */
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Onest', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    font-family: 'Onest', 'sans-serif' !important;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 40px !important;
    padding: 0 !important;
    margin: 0 !important;
    color: var(--text-color-dark) !important;
}

h3 {
    font-family: 'Onest', 'sans-serif' !important;
    font-size: 22px !important;
    font-weight: 600;
    line-height: 36px !important;
    padding: 0 !important;
    margin: 0 !important;
    color: var(--text-color-dark) !important;
}

h4 {
    font-family: 'Onest', 'sans-serif' !important;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 28px !important;
    padding: 0 !important;
    margin: 0 !important;
    color: var(--text-color-dark) !important;
}

h5 {
    font-family: 'Onest', 'sans-serif' !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    padding: 0 !important;
    margin: 0 !important;
    color: var(--text-color-dark) !important;
}

p {
    font-family: 'Poppins', 'sans-serif' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 26px !important;
    padding: 0;
    margin: 0 !important;
    color: var(--text-color-grey) !important;
}

/* this will make it easier... */
.flex-col {
    display: flex;
    flex-direction: column;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Typography classes because there is no consistency in Figma... */
.text-center {
    text-align: center;
}

.font-onest {
    font-family: 'Onest', 'sans-serif';
}
.font-poppins {
    font-family: 'Poppins', 'sans-serif';
}

.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}

.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-32 {
    font-size: 32px;
}

.lh-24 {
    line-height: 24px;
}
.lh-26 {
    line-height: 26px;
}
.lh-28 {
    line-height: 28px;
}
.lh-36 {
    line-height: 36px;
}

.text-color-black {
    color: #000000;
}
.text-color-dark {
    color: var(--text-color-dark);
}
.text-color-grey {
    color: var(--text-color-grey);
}
.text-color-blue {
    color: #0163e1;
}
.text-color-light-blue {
    color: rgba(30, 147, 255, 1);
}
.text-color-error {
    color: rgba(255, 0, 0, 0.7);
}
.text-color-success {
    color: rgba(0, 255, 0, 0.7);
}
.text-color-white {
    color: white;
}

.no-items-list-text {
    padding: 1.5rem;
    text-align: center;
}

@media (min-width: 768px) {
    .no-items-list-text {
        text-align: left;
        padding: 0;
    }
}

/* Form error message */
.error {
    font-family: 'Onest', 'sans-serif';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    padding: 0 !important;
    margin: 0 !important;
    color: rgba(255, 0, 0, 0.7);
}

.active-stepper-button {
    background-color: var(--primary-color) !important;
    border: none !important;
}

.approved-stepper-button {
    background-color: rgba(0, 161, 36, 1) !important;
    border: none !important;
}
